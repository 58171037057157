<template>
  <b-sidebar
    id="sidebar-invoice-edit-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
  <!-- #default="{ hide }" -->
    <template #default="{ hide }"  v-show="isShow" >
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Invoice
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
        />

      </div>
      <validation-observer ref="simpleRules">
      <!-- Body -->
      <b-form class="p-2">
        <h3 v-if="invdata">Payable Amount: {{ invdata.amount ? invdata.amount : null }}</h3>
          <!-- Change Amount -->
          <b-form-group
              label="Change Amount"
              label-for="change-amount"
          >
              <b-form-input
                  id="change-amount"
                  v-model="invdata.grand_total"
                  trim
              />
          </b-form-group>
          <!-- Last Payment Date -->
          <b-form-group
              label="Last Payment Date"
              label-for="last-payment-date"
          >
              <flat-pickr
                  v-model="invdata.last_payment_date"
                  class="form-control"
              />
          </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click.prevent="validationForm"
            @click="hide"
          >
            Update
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import {checkRes} from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

export default {
     mixins: [
        Mixin
    ],
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
     BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: ['invdata'],
  setup() {
    const payment_method = [
       {value: 1, text: 'Cash'}, {value: 2, text: 'GATEWAY'}
    ]
     return {
      payment_method,
      payment_method_value:1,
    }
  },
  data(){
 return {
      toggle: false,
      isShow:false,
      grand_total:0,
      amount:0,
      last_payment_date:null,
    }
  },
  methods:{
  validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                   useJwt.invoiceEditIsp({
                       invoice_id: this.invdata.id ?? null,
                       payment_method: this.payment_method_value ?? null,
                       last_payment_date: this.invdata.last_payment_date ?? null,
                       grand_total: this.invdata.grand_total ?? null
                    }).then(response => {
                       if (checkRes(response.status) && response.data.data) {
                           this.isShow = false
                           this.$router.push({ name: 'isp-invoice-preview' })
                            .catch(success => {
                                this.toastMessage('success','Invoice Updated',response)
                            })
                       }else {
                           this.$router.push({ name: 'isp-invoice-preview' }).then(() => {
                               this.toastMessage('warning','Invoice Updated',response)
                           }).catch(error => {
                               this.toastMessage('danger','Invoice Updated',response)
                           })
                       }
                    })
                }
            })
        }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
