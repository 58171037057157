<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'isp-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">

                  <h3 class="text-primary invoice-logo">
                   {{ invoiceData.isp.isp_setting.company_name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{ invoiceData.isp.isp_setting.company_address }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.isp.isp_setting.company_phone}}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.isp.isp_setting.company_email}}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
           <h3 class="text-success text-right"  v-if="invoiceData.payment_status_value ===  'Paid' ">
          <b-badge
            pill
            variant="light-success"
          >
              {{ invoiceData.payment_status_value }}
          </b-badge>
        </h3>
         <h3 class="text-warning text-right" v-if="invoiceData.payment_status_value ===  'Unpaid' ">
           <b-badge
            pill
            variant="light-warning"
          >
            {{ invoiceData.payment_status_value }}
          </b-badge>

        </h3>

                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">{{ invoiceData.invoice_code }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Invoice Create Date:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.invoice_date }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Last Payment Date:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.last_payment_date }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.user"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{ invoiceData.user.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ invoiceData.user.company }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.user.address }}
                </p>
                <p class="card-text mb-25">
                  {{ invoiceData.user.mobile }}
                </p>
                <p class="card-text mb-0">
                  {{ invoiceData.user.email }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['packageTitle', 'validity', 'price',{
              key: 'totalAmount', label: 'Total Amount', thStyle: { 'text-align':'right'}
            }]"
          >
              <template #cell(packageTitle)="pdata">
              <b-card-text class="font-weight-bold mb-25">
                {{ invoiceData.package_name }} ({{ invoiceData.isp_user_name}})
              </b-card-text>
            </template>
            <template #cell(validity)="vdata">
              <b-card-text class="font-weight-bold mb-25">
                  <div v-if="invoiceData.package_data && JSON.parse(invoiceData.package_data).validity">
                      Validity: {{ JSON.parse(invoiceData.package_data).validity }}
                  </div>
              </b-card-text>
            </template>
             <template #cell(price)="adata1">
              <b-card-text class="font-weight-bold mb-25">
             {{ currency }}   {{ invoiceData.amount  }}
              </b-card-text>
            </template>
             <template #cell(totalAmount)="tadata2">
              <b-card-text class="font-weight-bold mb-25 text-right">
              {{ currency }}  {{ invoiceData.amount  }}
              </b-card-text>
            </template>

          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <!-- <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span> -->
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                    {{ currency }}  {{ invoiceData.amount  }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                    {{ currency }} 0
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                   {{ currency }} 0
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                     {{ currency }}  {{ invoiceData.grand_total  }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <!-- <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span> -->
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
          <!-- Button: Edit -->
          <b-button v-if="invoiceData.payment_status == 0"
            v-b-toggle.sidebar-invoice-edit-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
          >
            Edit
          </b-button>
          <!-- Button: Edit -->
          <!-- Button: Add Payment -->
          <b-button v-if="invoiceData.payment_status == 0"
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            Add Payment
          </b-button>
           <!-- Button: Add Payment -->
          <b-button v-if="invoiceData.payment_status == 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-75"
            block
             @click="confirmDeleteInvoice"
          >
            Delete
          </b-button>
        </b-card>
      </b-col>
    </b-row>
      <!-- Check if invoiceData is not empty -->
      <invoice-sidebar-add-payment v-if="invoiceData" :invdata="invoiceData" />
      <invoice-sidebar-edit-payment v-if="invoiceData" :invdata="invoiceData" />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import BCardCode from "@core/components/b-card-code";
import useJwt from "@/auth/jwt/useJwt";
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,BBadge
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarEditPayment from '../InvoiceSidebarEditPayment.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'
import { checkRes } from "@core/services/helper";

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BCardCode,
    BBadge,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarEditPayment,
    InvoiceSidebarSendInvoice,
  },
  methods:{
     // comfirm button color
    confirmDeleteInvoice() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log(result);
        if (result.value) {
          useJwt
            .deleteInvoiceById({
              invoice_id: this.invoiceData.id,
            })
            .then((response) => {
              console.log(response.data.code);
              if (checkRes(response.data.code)) {
                this.$swal({
                  icon: "success",
                  title: "Deleted!",
                  text: "Your file has been deleted.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.$router
                  .push({ name: "isp-invoice-list" })
                  .catch((error) => {
                    this.toastMessage("success", "Delete Successfully", response);
                  });
              } else {
                this.$swal({
                  title: "Cancelled",
                  text: "Your imaginary file is safe :)",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Cancelled",
                text: "Your imaginary file is safe :)",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  setup() {
    const invoiceData = ref(null)
    const currency = ref(null)
    const paymentDetails = ref({})
   // const data = ref(null)

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        packageTitle: '',
        validity: '',
        price: '',
        totalAmount: '',
      },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-invoice/fetchInvoice', { invoiceId: router.currentRoute.params.invoiceId })
      .then(response => {
        invoiceData.value = response.data.data.invoice
        currency.value = response.data.data.currency
        paymentDetails.value = null
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      currency
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
